<template>
  <div class="Awarp">
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
  </div>
</template>
<script>
export default {
  name: "lvscBox6",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      searchObj: {},
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      this.$axios({
        url: Config.dev_url + "/api-apps/lowVoltage/rateOfPower",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
        },
      }).then((res) => {
        this.isLoading = true;
        this.detailInfo = {};
        var chart1 = res.data.data;
        this.detailInfo.chart1 = chart1;
        this.detailInfo.chart1.percent = Number(
          this.detailInfo.chart1.percent
        ).toFixed(2);
        this.initEchart();
        this.isLoading = false;
      });
    },
    initEchart() {
      var { percent } = this.detailInfo.chart1;
      var option = {
        animation: false,
        layoutAnimation: false,
        color: ["#DBDBDB", "#51A34B"],
        tooltip: {
          trigger: "item",
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          min: 0,
          max: 6.666,
          // boundaryGap: ['0', '10'],
          startAngle: 225,
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["a", "b", "c"],
          z: 10,
        },
        polar: [
          {
            center: ["50%", "50%"], //中心点位置
            radius: "97%", //图形大小
          },
        ],
        series: [
          {
            type: "bar",
            data: [, , 5],
            z: 0,
            silent: true,
            coordinateSystem: "polar",
            barMaxWidth: 1,
            barWidth: 10,
            name: "C",
            roundCap: true,
            color: {
              type: "linear",
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#2093E3", // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: "#51A34B ", // 0% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            barGap: "-100%",
          },
          // 背景
          {
            animation: false,
            name: "设备利用率",
            type: "gauge",
            radius: "100%",
            center: ["50%", "50%"],
            splitNumber: 12,
            // 展示当前进度
            progress: {
              show: true,
              width: 5,
              itemStyle: {
                color: "#DBDBDB",
                fontFamily: "ABBvoice_WCNSG_Rg",
              },
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            // 表盘中指针的固定点
            anchor: {
              show: false,
            },
            detail: {
              show: false,
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内容
          {
            name: "",
            type: "gauge",
            radius: "100%",
            center: ["50%", "50%"],
            min: 0,
            max: 100,
            zlevel: 10,
            splitNumber: 12,
            // 展示当前进度
            progress: {
              show: true,
              width: 5,
              itemStyle: {
                color: "#51A34B",
                fontFamily: "ABBvoice_WCNSG_Rg",
              },
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            splitNumber: 1,
            // 刻度标签
            axisLabel: {
              show: true,
              padding: [0, 10, 0, 10],
              distance: -30,
            },
            // 表盘中指针的固定点
            anchor: {
              show: false,
            },
            detail: {
              borderRadius: 8,
              offsetCenter: ["0%", "-15%"],
              fontSize: 30,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "rgba(0,0,0,.65)",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: percent,
                name: "负载率(%)",
              },
            ],
          },
        ],
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.lvscBox6 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
  .card-content2 {
    height: 93px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 28px 0 0;
    font {
      font-size: 16px;
      font-weight: 500;
      color: #1f1f1f;
      line-height: 20px;
    }
    span {
      display: flex;
      justify-content: space-between;
    }
    .content-li-line-box {
      width: 80%;
      margin: auto;
      height: 8px;
      display: flex;
      background: #dbdbdb;
      // margin: 12px 0 9px;
      span {
        background: #51A34B;
        height: inherit;
        width: 0%;
        display: inline-block;
      }
    }
    .content-li-line-box1{
      display: inline-block;
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: baseline;
    }
  }
}
</style>
